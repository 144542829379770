import { useCallback, useEffect, useMemo, useState } from 'react';

import { SELECTED_TEAM_KEY } from '../../Constants/TeamSelector';
import { TeamData } from '../../api/cmsApi/quickplayCmsApi';
import cacheManager from '../../utils/cache/buildCacheManager';
import useTeamsListState from '../quickplay/useTeamsListState';
import { useUpdateSearchParams } from './useUpdateSearchParams';

export const useTeamSearchParam = ({
  searchParamName = 'team',
  cache,
}: {
  searchParamName?: string;
  cache?: boolean;
}) => {
  const { teams, loading: teamLoading } = useTeamsListState();

  const [searchTeamId, updateSearchParams] = useUpdateSearchParams({ searchParamName });

  const initialTeamId = useMemo(() => {
    const cacheTeamId = cache ? cacheManager.load<string>(SELECTED_TEAM_KEY) : null;
    const selectedTeamId = searchTeamId || cacheTeamId;
    return selectedTeamId;
  }, [cache, searchTeamId]);

  const [currentSelectedTeamId, setSelectedTeamId] = useState(initialTeamId);

  const teamsById = useMemo(
    () =>
      teams.reduce((acc, team) => ({ ...acc, [team.id]: team }), {} as { [key: string]: TeamData }),
    [teams],
  );

  const selectedTeam = useMemo(
    () => (currentSelectedTeamId ? teamsById[currentSelectedTeamId] : null),
    [teamsById, currentSelectedTeamId],
  );

  const selectedTeamId = selectedTeam?.id;

  const selectTeam = useCallback((selectedTeam?: TeamData) => {
    setSelectedTeamId(selectedTeam?.id || null);
  }, []);

  useEffect(() => {
    if (cache) {
      if (selectedTeamId) {
        cacheManager.save(SELECTED_TEAM_KEY, selectedTeamId);
      } else {
        cacheManager.remove(SELECTED_TEAM_KEY);
      }
    }
    if (!teamLoading) {
      updateSearchParams(selectedTeamId || null);
    }
  }, [selectedTeamId]);

  return { selectTeam, selectedTeam, selectedTeamId, teamLoading, teams };
};
