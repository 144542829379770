import { useCallback, useEffect } from 'react';

import { useScrollIndicator } from '../../../hooks/useScrollIndicator';
import { GAME_TABLE_ID } from '../constants';

export const useGameRowIntersectionObserver = (
  itemRef: React.RefObject<HTMLDivElement>,
  runIntersectionObserverOnElement: boolean,
  onChangeMonth: (month: number, year: number) => void,
) => {
  const isScrolling = useScrollIndicator();

  const tableElement = document.querySelector(`#${GAME_TABLE_ID}`);
  const offsetTop = (tableElement?.getBoundingClientRect().top || 0) + window.scrollY;

  const handleIntersection: IntersectionObserverCallback = useCallback(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.target instanceof HTMLDivElement) {
          const [month, year] = (entry.target.dataset.monthyear || '').split('-');

          const isElementInThePlaceWhereWeTrackDoesMonthChange =
            entry.boundingClientRect.y <
            offsetTop + 1.5 * (itemRef.current?.getBoundingClientRect()?.height || 0);

          if (
            !entry.isIntersecting &&
            isElementInThePlaceWhereWeTrackDoesMonthChange &&
            entry.intersectionRatio > 0.6
          ) {
            onChangeMonth(parseInt(month), parseInt(year));
          }

          if (entry.isIntersecting && isElementInThePlaceWhereWeTrackDoesMonthChange) {
            onChangeMonth(parseInt(month), parseInt(year));
          }
        }
      });
    },
    [offsetTop, onChangeMonth],
  );

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: `-${offsetTop}px 0px 0px 0px`,
      threshold: 1,
    });

    if (itemRef.current && runIntersectionObserverOnElement && isScrolling) {
      observer.observe(itemRef.current);
    }

    if (!runIntersectionObserverOnElement && itemRef.current) {
      observer.unobserve(itemRef?.current);
    }

    return () => {
      if (itemRef.current) {
        observer.unobserve(itemRef.current);
      }
    };
  }, [itemRef.current, runIntersectionObserverOnElement, isScrolling]);
};
