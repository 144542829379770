import { format, getMonth, getYear } from 'date-fns';
import { useRef } from 'react';
import { BsCircleFill } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { ROUTES, RouteName } from '../../../../Constants/routes';
import { Video } from '../../../../api';
import { useExtendedNavigate } from '../../../../hooks/useExtendedNavigate';
import { CheckSubscriptionParamsType } from '../../../../hooks/useSubscriptionNavigate';
import { useTranslations } from '../../../../hooks/useTranslations';
import { activeSubscriptionsSelector } from '../../../../store/activeSubscriptions';
import { entitlementsSelector } from '../../../../store/entitlements';
import { AppDispatch } from '../../../../store/store';
import { displayVideo } from '../../../../store/videos';
import { getContextualDateLabel } from '../../../../utils/getContextualDateLabel';
import { ClickEventDispatcher } from '../../../ClickEventDispatcher/ClickEventDispatcher';
import { usePauseSubscription } from '../../../Settings/hooks/usePauseSubscription';
import { useGameRowIntersectionObserver } from '../../hooks/useGameRowIntersectionObserver';
import { ActionButton } from '../ActionButton/ActionButton';
import { Team } from '../Team/Team';
import * as Ui from './GameRow.styles';
import { useGameLiveStatus } from './useGameLiveStatus';

interface Props {
  checkSubscription: (
    asset: Video | null,
    checkSubscription?: CheckSubscriptionParamsType,
  ) => Promise<boolean>;
  game: Video;
  onChangeMonth: (month: number, year: number) => void;
  runIntersectionObserverOnElement: boolean;
}

export const LIVE_GAME_CLASS_NAME = 'live';

export const GameRow = ({
  game,
  onChangeMonth,
  checkSubscription,
  runIntersectionObserverOnElement,
}: Props) => {
  const itemRef = useRef<HTMLDivElement>(null);
  const t = useTranslations();
  const isLive = useGameLiveStatus(game);
  const navigate = useExtendedNavigate();
  const dispatch: AppDispatch = useDispatch();
  const currentLocation = useLocation();
  const { hasActiveSubscriptions } = useSelector(activeSubscriptionsSelector);
  const { ppgItemsIds } = useSelector(entitlementsSelector);

  const isAvailable = ppgItemsIds[game?.externalId] || hasActiveSubscriptions;
  const startDate = new Date(game.startDate);
  const month = getMonth(startDate);
  const year = getYear(startDate);
  const startedInPast = startDate.getTime() < new Date().getTime();
  const isUpcoming = startDate.getTime() > new Date().getTime();
  const homeTeam = game.rawData?.hm_tm || '';
  const visitorTeam = game.rawData?.aw_tm || '';
  const scopeTeams = ['Rangers', 'Islanders', 'Devils'];

  const { handleResumeSubscription, isSubscriptionPaused } = usePauseSubscription();

  useGameRowIntersectionObserver(itemRef, runIntersectionObserverOnElement, onChangeMonth);

  const bothTeamMSG = (): boolean => {
    const isFirstTeamMSG = game.rawData?.tm?.[0]?.pmy_tm;
    const isSecondTeamMSG = game.rawData?.tm?.[1]?.pmy_tm;

    return isFirstTeamMSG === isSecondTeamMSG;
  };
  const teamBroadcast = (teamName?: string): boolean => {
    return game?.rawData?.p_losn?.[0]?.n === teamName;
  };
  const teamsInScope: boolean = scopeTeams.includes(homeTeam) && scopeTeams.includes(visitorTeam);

  const shouldDisplayLabel: boolean = teamsInScope && bothTeamMSG();

  const handleOnClick = () => {
    if (isSubscriptionPaused) {
      return handleResumeSubscription();
    }
    checkSubscription(game, {
      onSuccess: () =>
        navigate(`${ROUTES[RouteName.AllSetPayment]}`, { state: { backRoute: currentLocation } }),
      replaceRoute: false,
    }).then((playbackAuthorized) => {
      if (playbackAuthorized) {
        if (game.isLive || startedInPast) {
          dispatch(displayVideo(game));
          navigate(
            `${ROUTES[RouteName.ScheduleGamesPlayer]}?contentType=${game.contentType}&id=${
              game.id
            }${game.isLive ? `&isLive=${game.isLive}` : ''}`,
            {
              state: { goBackWhenNotAuthorized: true },
            },
          );
        }
      }
    });
  };

  return (
    <ClickEventDispatcher
      destination='/schedule/games'
      location='Module: Schedule'
      text='CTA: Play Filters Option'
    >
      <Ui.Row
        ref={itemRef}
        $isDark={startedInPast && !isLive}
        key={game.id}
        className={game.isLive ? LIVE_GAME_CLASS_NAME : ''}
        onClick={handleOnClick}
        $isAvailable={isAvailable && isUpcoming}
        data-monthyear={`${month}-${year}`}
      >
        <Ui.TeamsWrapper>
          <Team
            team={game.visitorTeam}
            teamBroadcast={shouldDisplayLabel && teamBroadcast(game?.rawData?.aw_tm)}
          />
          <Team
            team={game.homeTeam}
            teamBroadcast={shouldDisplayLabel && teamBroadcast(game?.rawData?.hm_tm)}
          />
        </Ui.TeamsWrapper>

        <Ui.Time>
          {isLive ? (
            <Ui.LivePill>
              <BsCircleFill /> {t.global_live}
            </Ui.LivePill>
          ) : (
            <span>{getContextualDateLabel(t, startDate)}</span>
          )}
          <span>{format(startDate, 'h:mm a')}</span>
        </Ui.Time>
        <ActionButton
          game={game}
          checkSubscription={checkSubscription}
          handleOnClickPlay={handleOnClick}
        />
      </Ui.Row>
    </ClickEventDispatcher>
  );
};
