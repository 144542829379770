/* eslint-disable @typescript-eslint/no-explicit-any */
import { PlatformType, UserType, version } from '@msgn/fl-module/fl-analytics';
import { Logger } from '@msgn/fl-module/fl-foundation';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { dataZoomApi } from '../api/dataZoomApi/dataZoomApi';
import { useEnv } from '../components/EnvProvider/EnvProvider';
import { CustomLogger } from '../helpers/CustomLogger';
import { activeSubscriptionsSelector } from '../store/activeSubscriptions';
import { authSelector } from '../store/auth';
import { configSelector } from '../store/config';
import { userDataSelector } from '../store/userData';
import { DataZoomType } from './userDataZoom.types';

export const useDataZoom = (): DataZoomType => {
  const [userSession, setUserSession] = useState<any>(null);
  const { DATA_ZOOM_KEY, DATA_ZOOM_LOGGER } = useEnv();

  const {
    hasActiveSubscriptions,
    lastSubscription,
    loaded: isLoadedActiveSubscription,
  } = useSelector(activeSubscriptionsSelector);
  const { cpCustomerID, loaded: isLoadedUserData } = useSelector(userDataSelector);
  const { successSignup } = useSelector(authSelector);
  const { startedDate } = useSelector(configSelector);

  const appSession = useMemo(() => {
    const appInfo = {
      build: process.env.REACT_APP_VERSION || '',
      name: 'MSG Web',
      version,
    };
    const deviceInfo = {
      platformType: PlatformType.WEB,
    };
    const userInfo = {
      type: UserType.ANONYMOUS,
    };
    const appSessionInstance = dataZoomApi.init();

    const logger = new CustomLogger(DATA_ZOOM_LOGGER);

    const analyticsConfiguration = {
      application: appInfo,
      configId: DATA_ZOOM_KEY,
      device: deviceInfo,
      logger: logger as unknown as Logger,
      user: userInfo,
    };

    appSessionInstance.initialize(analyticsConfiguration);

    const startupTime = new Date().getTime() - startedDate;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    appSessionInstance.reporter.initialized && appSessionInstance.start({ startupTime });
    return appSessionInstance;
  }, [startedDate]);

  const userInfo = useMemo(() => {
    if (!isLoadedUserData || !isLoadedActiveSubscription) return null;

    let userType = UserType.ANONYMOUS;
    let customerId = null;
    let subscription = null;

    if (cpCustomerID) {
      userType = UserType.REGISTERED;
      customerId = cpCustomerID;
    }

    if (hasActiveSubscriptions) {
      userType = UserType.SUBSCRIBED;
      const { serviceID, serviceName, subscriptionStatus, orderID } = lastSubscription || {};

      subscription = {
        id: orderID,
        plan: serviceName,
        planId: serviceID,
        status: subscriptionStatus,
      };
    }

    return {
      type: userType,
      ...(customerId && { id: customerId }),
      ...(subscription && { subscription }),
    };
  }, [
    isLoadedUserData,
    isLoadedActiveSubscription,
    hasActiveSubscriptions,
    lastSubscription,
    cpCustomerID,
  ]);

  const initUserSession = useCallback(
    (user: Exclude<typeof userInfo, null>) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (!appSession || !appSession?.reporter.initialized) {
        return;
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const userSessionInstance = appSession.createUserSession(user);
      if (successSignup) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        userSessionInstance.signup(user);
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      userSessionInstance.login(user);
      setUserSession(userSessionInstance);
    },
    [appSession, successSignup],
  );

  useEffect(() => {
    if (userInfo) {
      initUserSession(userInfo);
    }
  }, [userInfo, initUserSession]);

  return {
    appSession,
    userInfo,
    userSession,
  };
};
