import { ROUTES, RouteName } from '../../Constants/routes';
import * as AuthFormUi from '../../components/AuthForm/AuthForm.styles';
import { AuthPageWrapper } from '../../components/AuthPageWrapper/AuthPageWrapper';
import { ClickEventDispatcher } from '../../components/ClickEventDispatcher/ClickEventDispatcher';
import { Row } from '../../components/Common';
import { useExtendedNavigate } from '../../hooks/useExtendedNavigate';
import { useTranslations } from '../../hooks/useTranslations';

const ForgotPasswordDone = () => {
  const t = useTranslations();
  const navigate = useExtendedNavigate();

  const cleanUserData = () => {
    navigate('/login', { replace: true });
  };

  return (
    <AuthPageWrapper
      screen='Forgot Password'
      location='forgotPassword'
      pathname='/forgot-password-done'
      title={t.forgotPassword_subtitle}
      subTitle={t.forgotPasswordDone_message}
      previousLocation={ROUTES[RouteName.Welcome]}
    >
      <Row>
        <AuthFormUi.ButtonWrapperCol>
          <ClickEventDispatcher
            destination='/login'
            location='Module: Forget password done'
            text={`CTA: ${t.forgotPassword_closeButton}`}
          >
            <AuthFormUi.Button onPress={cleanUserData} disabled={false}>
              {t.forgotPassword_closeButton}
            </AuthFormUi.Button>
          </ClickEventDispatcher>
        </AuthFormUi.ButtonWrapperCol>
      </Row>
    </AuthPageWrapper>
  );
};

export default ForgotPasswordDone;
