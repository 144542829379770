import { useCallback } from 'react';
import { NavigateFunction, To, useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { NavigateOptions } from 'react-router/dist/lib/context';

import { ROUTES, RouteName } from '../Constants/routes';

export const useExtendedNavigate = (): NavigateFunction => {
  const navigate = useNavigate();
  const location = useLocation();

  const extendedNavigate = useCallback(
    (path: To, options?: NavigateOptions) => {
      if (!navigator.onLine) {
        return navigate(ROUTES[RouteName.Networkerror], {
          replace: false,
          state: { desiredLocation: path },
        });
      }

      return navigate(path, {
        ...options,
        state: {
          ...options?.state,
          previousLocation: location?.pathname + location.search,
        },
      });
    },
    [navigate, location.pathname, location.search],
  );

  return <NavigateFunction>extendedNavigate;
};
