import { useCallback } from 'react';

import { useExtendedSearchParams } from '../useExtendedSearchParams';

export const useUpdateSearchParams = ({
  searchParamName,
}: {
  searchParamName: string;
}): [string | null, (value: string | null) => void] => {
  const [searchParams, setSearchParams] = useExtendedSearchParams();
  const searchParamValue = searchParams.get(searchParamName);

  const updateSearchParams = useCallback(
    (newValue: string | null) => {
      if (!searchParamValue && !newValue) return;
      setSearchParams(
        (prev) => {
          const newParams: { [key: string]: string } = {};

          prev.forEach((value, paramName) => {
            newParams[paramName] = value;
          });

          if (newValue) {
            newParams[searchParamName] = newValue;
          } else {
            delete newParams[searchParamName];
          }
          return newParams;
        },
        { replace: true },
      );
    },
    [searchParamValue, setSearchParams, searchParamName],
  );

  return [searchParamValue, updateSearchParams];
};
