import { getImageResizeUrl } from '../../helpers/imageResizeUrl';
import useImageSafe from '../../hooks/useImageSafe';
import { AspectRatioKeys } from '../../store/videos';
import * as Ui from './TeamShield.styles';

const getTeamImageUrl = (teamId: string, teamImageURL?: string): string => {
  if (teamImageURL) return teamImageURL;

  const imageUrl = getImageResizeUrl({ assetId: teamId, size: AspectRatioKeys.A1x1 });
  const { url: imageUrlSafe } = useImageSafe(imageUrl);

  return imageUrlSafe;
};

export const TeamShield = ({
  teamId,
  teamName,
  teamImageURL,
  selected,
  small,
  showTitle = false,
  withCircle = false,
  onClick,
  ...rest
}: {
  teamId: string;
  teamName: string;
  teamImageURL?: string;
  showTitle?: boolean;
  withCircle?: boolean;
  selected?: boolean;
  small?: boolean;
  className?: string;
  onClick?: () => void;
}) => {
  const imageUrl = getTeamImageUrl(teamId, teamImageURL);
  if (!withCircle) {
    return <img src={imageUrl} alt={teamName} width={20} {...rest} />;
  }
  return (
    <Ui.TeamShieldContainer
      onClick={onClick}
      $selected={selected}
      $selectable={!!onClick}
      $small={small}
      {...rest}
    >
      <img src={imageUrl} alt={teamName} />
      {showTitle ? <span>{teamName}</span> : null}
    </Ui.TeamShieldContainer>
  );
};
