import { useCallback, useEffect, useState } from 'react';

import { PlayerTrack } from '../../../api/playerApi/playerApi.types';
import { CloseCaption } from '../../../assets/icons/CloseCaptions';
import { CloseCaptionFilled } from '../../../assets/icons/CloseCaptionsFilled';
import { usePlayerControlsContext } from '../../PlayerControls/PlayerControlsProvider';
import * as Ui from '../PlayerUi.styles';
import { CC_VALUE_KEY } from '../constants';
import ClosedCaptionsModal from './ClosedCaptionsModal';

export const ClosedCaptionsButton = () => {
  const { countTracks } = usePlayerControlsContext();

  if (!countTracks) return null;

  return <ClosedCaptionsButtonBase />;
};

const ClosedCaptionsButtonBase = () => {
  const { handleChangeTrack, tracksGroups, activeTracks, ccActive, setCcActive } =
    usePlayerControlsContext();
  const [opened, setOpened] = useState(false);

  const onModalHide = useCallback(() => {
    setOpened(false);
  }, []);

  const onSelectTrack = useCallback(
    (track?: PlayerTrack) => {
      handleChangeTrack(track);
      onModalHide();
    },
    [handleChangeTrack, onModalHide],
  );

  const handleAction = useCallback(() => {
    if (tracksGroups.text.length === 1) {
      setCcActive(!ccActive);
    } else {
      setOpened(true);
    }
  }, [tracksGroups?.text?.length, setCcActive, ccActive]);

  useEffect(() => {
    setCcActive(localStorage.getItem(CC_VALUE_KEY) === 'true');
  }, []);

  useEffect(() => {
    localStorage.setItem(CC_VALUE_KEY, ccActive ? 'true' : 'false');
  }, [ccActive]);

  return (
    <>
      {tracksGroups.text.length > 0 && (
        <>
          <Ui.PlayerButton aria-label='CC' onClick={handleAction} onClickCapture={handleAction}>
            {ccActive ? <CloseCaptionFilled /> : <CloseCaption />}
          </Ui.PlayerButton>

          <ClosedCaptionsModal
            show={opened}
            onHide={onModalHide}
            onSelectTrack={onSelectTrack}
            tracks={tracksGroups.text}
            activeTrack={activeTracks.text}
          />
        </>
      )}
    </>
  );
};

export default ClosedCaptionsButton;
