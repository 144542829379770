import styled from 'styled-components';

import bgBlueSrc from '../../assets/images/minimized/bg_blue_1920.jpg';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background-image: url(${bgBlueSrc});
  background-color: #02101f;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  min-height: 100vh;
  min-height: 100svh;
`;
