import { createError } from '@msgn/fl-module/fl-foundation';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import {
  AssetContentType,
  ConsumptionType,
  ContentAuthorizer,
  DrmScheme,
  MediaType,
  PlaybackParams,
  Video,
  flpPlayerApi,
} from '../../api';
import { useConvivaContext } from '../../components/ConvivaProvider/ConvivaProvider';
import { useEnv } from '../../components/EnvProvider/EnvProvider';
import { isSafari } from '../../helpers/browser';
import { useTranslations } from '../../hooks/useTranslations';
import { activeSubscriptionsSelector } from '../../store/activeSubscriptions';
import { authSelector } from '../../store/auth';
import { deviceInfoSelector } from '../../store/deviceInfo';
import { entitlementsSelector } from '../../store/entitlements';
import { playerActions, playerAssetLoadingSelector, playerAssetSelector } from '../../store/player';
import { setPlayerError } from '../../store/player/player.thunks';
import { AppDispatch } from '../../store/store';
import { shouldVideoBeAvailableSelector } from '../../store/videos/videos.selectors';
import { zoneInfoStateSelector } from '../../store/zoneInfo';
import { mapAssetErrorMessage } from '../../utils/mapAssetErrorMessage';
import { useSSAIHeaders } from './useSSAIHeaders';
import { getPPGHeaders, getTveHeaders } from './utils/headers';

export const getAuthorizePlaybackParams = (selectedVideo: Video | null): PlaybackParams => {
  const contentId = (
    selectedVideo?.playbackContentType === AssetContentType.CHANNEL && selectedVideo?.cid
      ? selectedVideo.cid
      : selectedVideo?.id
  ) as string;
  const consumptionType =
    selectedVideo?.playbackContentType === AssetContentType.EPISODE
      ? ConsumptionType.VOD
      : ConsumptionType.LIVE;

  return {
    catalogType: selectedVideo?.playbackContentType || '',
    consumptionType,
    drmScheme: isSafari ? DrmScheme.FAIRPLAY : DrmScheme.WIDEVINE,
    mediaID: contentId,
    mediaType: isSafari ? MediaType.HLS : MediaType.DASH,
    playbackMode: selectedVideo?.playbackMode,
  };
};

export const useFLPPlatformAsset = ({
  selectedVideo,
  contentAuthorizer,
  isAuthorized,
}: {
  selectedVideo: Video | null;
  contentAuthorizer: ContentAuthorizer;
  isAuthorized: boolean;
}) => {
  const shouldVideoBeAvailable = useSelector(shouldVideoBeAvailableSelector);
  const t = useTranslations();
  const { IS_PLAYER_IN_STORYBOOK_MODE } = useEnv();
  const dispatch: AppDispatch = useDispatch();
  const loading = useSelector(playerAssetLoadingSelector);

  const { entitlements } = useSelector(entitlementsSelector);
  const asset = useSelector(playerAssetSelector);
  const {
    hasActiveSubscriptions,
    lastSubscription,
    loaded: subscriptionLoaded,
  } = useSelector(activeSubscriptionsSelector);
  const { tveToken } = useSelector(authSelector);

  const { deviceId } = useSelector(deviceInfoSelector);
  const { zipCode } = useSelector(zoneInfoStateSelector);

  const { updateContentMetadata, convivaSession } = useConvivaContext();

  const tvodToken = useMemo(
    () => (!hasActiveSubscriptions ? entitlements?.tvodToken : null) || null,
    [hasActiveSubscriptions, entitlements?.tvodToken],
  );

  const params = useMemo<PlaybackParams>(() => {
    if (selectedVideo && subscriptionLoaded) {
      return {
        ...getAuthorizePlaybackParams(selectedVideo),
        ...useSSAIHeaders(selectedVideo, deviceId, zipCode, lastSubscription),
      };
    }

    return {} as PlaybackParams;
  }, [selectedVideo, subscriptionLoaded, deviceId, zipCode, lastSubscription]);

  const headers = useMemo(
    () => ({
      ['X-Tracking-Id']: uuid(),
      ...getTveHeaders(tveToken),
      ...getPPGHeaders(tvodToken),
    }),
    [tvodToken, tveToken],
  );

  const loadAsset = useCallback(async () => {
    if (!selectedVideo || IS_PLAYER_IN_STORYBOOK_MODE) {
      return;
    }

    if (Object.entries(params).length === 0 || !isAuthorized) {
      dispatch(playerActions.setPlayerAssetLoading(false));
      dispatch(playerActions.setPlayerAsset());
      return;
    }
    dispatch(playerActions.setPlayerAssetLoading(true));
    dispatch(playerActions.setPlayerAsset());
    dispatch(playerActions.setPlayerAssetError(undefined));

    if (!shouldVideoBeAvailable) {
      dispatch(
        setPlayerError({
          errorMessage: t.playerContentAvailability,
        }),
      );
      return;
    }

    try {
      const loadedAsset = await flpPlayerApi.authorizePlayback(contentAuthorizer, params, headers);

      dispatch(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        playerActions.setPlayerAsset({ ...loadedAsset, contentType: selectedVideo?.contentType }),
      );
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      updateContentMetadata({ ...loadedAsset, contentType: selectedVideo?.contentType });
      dispatch(playerActions.setPlayerAssetError(undefined));

      dispatch(playerActions.setPlayerAssetLoading(false));
    } catch (error: unknown) {
      let errorCode = '';

      if (typeof error === 'object' && error !== null && 'errorCode' in error) {
        errorCode = String(error?.errorCode);
      }
      convivaSession?.reportError(createError(parseInt(errorCode), mapAssetErrorMessage(error)));

      dispatch(
        playerActions.setPlayerAssetError({
          errorCode,
          errorMessage: mapAssetErrorMessage(error),
        }),
      );
      dispatch(playerActions.setPlayerAssetLoading(false));
    }
  }, [
    params,
    isAuthorized,
    headers,
    selectedVideo?.contentType,
    shouldVideoBeAvailable,
    updateContentMetadata,
  ]);

  useEffect(() => {
    loadAsset();
  }, [loadAsset]);

  return { asset, loadAsset, loading, tveToken, tvodToken };
};

export default useFLPPlatformAsset;
