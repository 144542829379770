import React, { useEffect } from 'react';

import { FirstLoadProps } from '../../routes/Routes';

const Splash: React.FC<FirstLoadProps> = ({ setFirstLoad, firstLoad }) => {
  useEffect(() => {
    if (firstLoad && !(window.location.pathname == '/' || window.location.pathname == '/welcome')) {
      setFirstLoad(false);
    }
  }, []);

  return <></>;
};

export default Splash;
