import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  GetProductsResponseItem,
  ProductServiceTypeEnum,
} from '../../../api/authApi/authApi.types';
import { buttonClicked } from '../../../api/mParticlesApi/mParticleEvents/generic/genericEvents';
import { purchasePlanSelectedEvent } from '../../../api/mParticlesApi/mParticleEvents/purchase/purchaseEvents';
import useTeamsListState from '../../../hooks/quickplay/useTeamsListState';
import { useLogScreenViewedEvent } from '../../../hooks/useLogScreenViewedEvent';
import { useProceedToPaymentSelection } from '../../../hooks/useProceedToPaymentSelection';
import { useTranslations } from '../../../hooks/useTranslations';
import { RECOMMENT_PRODUCT_IDX, fetchProducts, selectProduct } from '../../../store/products';
import {
  availableProductsSelector,
  productsStateSelector,
} from '../../../store/products/products.selectors';
import { AppDispatch } from '../../../store/store';
import { videosActions } from '../../../store/videos';
import { zoneInfoStateSelector } from '../../../store/zoneInfo';
import { zipCodeRegx } from '../../../utils/validations';
import { BackButton } from '../../BackButton/BackButton';
import { ButtonSize, ButtonVariant } from '../../Button/Button.types';
import { ClickEventDispatcher } from '../../ClickEventDispatcher/ClickEventDispatcher';
import { Col, Row } from '../../Common';
import { useDataZoomContext } from '../../DataZoomProvider/context';
import { ErrorComponent, IconType } from '../../ErrorComponent/ErrorComponent';
import { LEGAL_LINK } from '../../LegalLink/LegalLink';
import { LoadingErrorTryAgain } from '../../LoadingErrorTryAgain/LoadingErrorTryAgain';
import { GameDetails } from '../../SubscriptionPlans/GameDetails/GameDetails';
import PlanCard from '../../SubscriptionPlans/PlanCard/PlanCard';
import { ConnectingProviderModal } from '../../TVEProviderComponents/ConnectingProviderModal/ConnectingProviderModal';
import { TeamShield } from '../../TeamShield/TeamShield';
import { ZipCodeForm } from '../../ZipCodeForm/ZipCodeForm';
import { useModalContext } from '../context';
import * as Ui from './SubscriptionModalBody.styles';

export const SUBSCRIPTION_PURCHASE_ITEM = 'subscription-purchase-item';

interface Props {
  onSuccess: (product: GetProductsResponseItem) => void;
  onSkip: () => void;
  redirectBackUrl?: string;
  onGoBack?: () => void;
  applyCoupon?: boolean;
}

export const SubscriptionModalBody = ({
  applyCoupon,
  onSuccess,
  onSkip,
  redirectBackUrl,
  onGoBack,
}: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const t = useTranslations();
  const { openModal, closeModal } = useModalContext();
  const { userInfo, userSession } = useDataZoomContext();
  const { selectedProduct, loaded, error } = useSelector(productsStateSelector);
  const products = useSelector(availableProductsSelector);

  const { zipCode, updating, updateError } = useSelector(zoneInfoStateSelector);
  const { teams } = useTeamsListState();

  useLogScreenViewedEvent('select-a-plan');

  const handleSkip = () => {
    buttonClicked({
      destination: '/home',
      location: 'Module: Subscription',
      path: window.location.pathname,
      screen: 'Subscription Plans',
      text: 'CTA: Skip',
      type: 'Button',
      url: window.location.href,
    });
    onSkip();
  };

  useEffect(() => {
    if (userSession && userInfo) {
      userSession.startSubscription(userInfo);
    }
  }, [userSession, userInfo]);

  // reset selected product to default one
  useEffect(() => {
    return () => {
      dispatch(selectProduct(products[RECOMMENT_PRODUCT_IDX] ?? products[0]));
    };
  }, []);

  useEffect(() => {
    if (selectedProduct) {
      purchasePlanSelectedEvent({
        price: selectedProduct.retailPrice,
        // eslint-disable-next-line camelcase
        purchase_plan: selectedProduct.displayName?.toLowerCase(),
      });
    }
  }, [selectedProduct?.productID]);

  const handleGoBackAction = useCallback(() => {
    closeModal();
    dispatch(videosActions.purchaseClosed());
    onGoBack?.();
    sessionStorage.removeItem(SUBSCRIPTION_PURCHASE_ITEM);
  }, [closeModal, dispatch, onGoBack]);

  const openConnectingProviderModal = useCallback(() => {
    if (!selectedProduct) return;

    openModal(<ConnectingProviderModal redirectBackUrl={redirectBackUrl || ''} />, {
      fullscreen: true,
    });
  }, [openModal, selectedProduct, redirectBackUrl]);

  const handleProceedToPaymentSelection = useProceedToPaymentSelection({
    applyCoupon,
    onGoBack,
    onSkip,
    onSuccess,
  });

  return (
    <Ui.ModalBody>
      <Ui.Container>
        <Ui.BackButtonWrapper>
          <ClickEventDispatcher
            location='Module: Select Subscription'
            screen='Select Subscription Modal'
            text='CTA: Back'
          >
            <BackButton onClick={handleGoBackAction} isFloating={false} />
          </ClickEventDispatcher>
        </Ui.BackButtonWrapper>

        <Ui.ModalHeader onHide={handleSkip}>
          <Ui.ModalTitle></Ui.ModalTitle>
        </Ui.ModalHeader>

        <Ui.TitleHeader>{t.subscription_title}</Ui.TitleHeader>

        <Row $flex $justifyCenter>
          <Col xs={12} xxl={8}>
            <ZipCodeForm />
          </Col>
        </Row>

        {!zipCode && (
          <Ui.MissingZipCodeErrorWrapper>
            <ErrorComponent
              icon={IconType.InfoError}
              error={t.subscription_missingZipCode_title}
              description={t.subscription_missingZipCode_description}
            />
          </Ui.MissingZipCodeErrorWrapper>
        )}

        {error && (
          <Ui.ErrorRow>
            <Col xs={12} md={6} xxl={4}>
              <LoadingErrorTryAgain
                error={error}
                tryAgain={() => {
                  dispatch(fetchProducts());
                }}
                loading={false}
                fullPage
              />
            </Col>
          </Ui.ErrorRow>
        )}

        {loaded && (
          <>
            <Row $flex $justifyCenter>
              <Col xs={12} xl={10}>
                <Ui.PlanList>
                  {products.map((dataProduct) => {
                    const isPPG = dataProduct.serviceType !== ProductServiceTypeEnum.SVOD;

                    return (
                      <PlanCard
                        key={dataProduct.productID}
                        dataPlan={dataProduct}
                        selected={dataProduct.productID === selectedProduct?.productID}
                        onPress={() => dispatch(selectProduct(dataProduct))}
                      >
                        {isPPG && <GameDetails />}
                      </PlanCard>
                    );
                  })}
                </Ui.PlanList>

                <Ui.OrDivider>
                  <Ui.OrDividerLine />
                  <Ui.OrText>{t.global_or}</Ui.OrText>
                  <Ui.OrDividerLine />
                </Ui.OrDivider>

                <Ui.ConnectTVEProviderButton
                  variant={ButtonVariant.Secondary}
                  onPress={openConnectingProviderModal}
                >
                  {t.connectTVEProviderLink_link}
                </Ui.ConnectTVEProviderButton>

                {teams?.length > 0 && (
                  <Ui.TeamsWrapper>
                    {teams.map((teamData, idx) => (
                      <TeamShield
                        teamId={teamData.teamId}
                        teamName={teamData.teamName}
                        small
                        key={idx}
                        withCircle
                      />
                    ))}
                  </Ui.TeamsWrapper>
                )}
              </Col>
            </Row>

            <Row $flex $justifyCenter>
              <Col xs={12} xl={8}>
                <Ui.LegalWrapper>
                  {t.subscription_faq_1}
                  <Ui.LegalLink
                    to={LEGAL_LINK.faqs}
                    className={'text-decoration-none fw-bold text-warning'}
                  >
                    &nbsp;{t.subscription_faqLink}&nbsp;
                  </Ui.LegalLink>
                  {t.subscription_faq_2}
                </Ui.LegalWrapper>
              </Col>
            </Row>
          </>
        )}

        {!error && (
          <Ui.PurchaseRow>
            <Col xs={12} md={6} xl={4}>
              <Ui.PurchaseButton
                disabled={
                  !selectedProduct ||
                  !zipCode ||
                  !zipCode.match(zipCodeRegx) ||
                  !!updateError ||
                  updating
                }
                size={ButtonSize.Small}
                onPress={handleProceedToPaymentSelection}
              >
                {t.subscription_purchaseButton}
              </Ui.PurchaseButton>
            </Col>
          </Ui.PurchaseRow>
        )}
      </Ui.Container>
    </Ui.ModalBody>
  );
};
