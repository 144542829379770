import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { PROMO_PARAM_KEY } from '../../Constants/promotions';
import { GetProductsResponseItem } from '../../api/authApi/authApi.types';
import { useForm } from '../../hooks/useForm';
import { useTranslations } from '../../hooks/useTranslations';
import { applyVoucherCode, purchaseSelector } from '../../store/purchase';
import { AppDispatch } from '../../store/store';
import * as AuthFormUi from '../AuthForm/AuthForm.styles';
import { ButtonVariant } from '../Button/Button.types';
import { Loader } from '../Loader/Loader';
import * as Ui from './VoucherCode.styles';

type FormData = {
  voucherCode: string;
};

export interface VoucherCodeProps {
  applyCoupon?: boolean;
  product: GetProductsResponseItem;
}

export const VoucherCode = ({ applyCoupon, product }: VoucherCodeProps) => {
  const t = useTranslations();
  const [searchParams] = useSearchParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    setValue,
  } = useForm<FormData>();
  const dispatch: AppDispatch = useDispatch();
  const { voucherCodeLoading, voucherCodeError } = useSelector(purchaseSelector);

  const onSubmit = handleSubmit((values) => {
    dispatch(
      applyVoucherCode({
        serviceId: product.skuORQuickCode,
        voucherCode: values.voucherCode,
      }),
    );
  });

  useEffect(() => {
    if (voucherCodeError) {
      setError('voucherCode', { message: voucherCodeError.message, type: 'custom' });
    }
  }, [voucherCodeError]);

  useEffect(() => {
    if (!applyCoupon) {
      return;
    }

    const coupon = searchParams.get(PROMO_PARAM_KEY.coupon);

    if (coupon) {
      setValue('voucherCode', coupon);
      onSubmit();
    }
  }, [searchParams, applyCoupon]);

  return (
    <>
      {voucherCodeLoading && <Loader absolute withBackdrop />}
      <Ui.Label>{t.voucherCode_label}</Ui.Label>
      <Ui.Form onSubmit={onSubmit}>
        <Ui.Input {...register('voucherCode')} placeholder={t.voucherCode_placeholder} />
        <Ui.SubmitButton type='submit' variant={ButtonVariant.Secondary}>
          {t.global_apply}
        </Ui.SubmitButton>
        {errors.voucherCode && (
          <Ui.ErrorContentWrapper>
            <AuthFormUi.ErrorLine>{errors.voucherCode.message}</AuthFormUi.ErrorLine>
          </Ui.ErrorContentWrapper>
        )}
      </Ui.Form>

      <Ui.Hr />
    </>
  );
};
