import { addDays, format, subDays } from 'date-fns';
import { useMemo } from 'react';

import { DayFilter } from '../components/Schedule/components/DaysFilter/DaysFilter.types';
import { useTranslations } from './useTranslations';

const FORMAT = 'E MM/dd';

export const useDaysFilter = () => {
  const t = useTranslations();

  const daysFilter = useMemo<DayFilter[]>(() => {
    const today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    const tomorrow = subDays(today, -1);

    const dates = [
      { label: t.global_today, value: today },
      { label: t.global_tomorrow, value: tomorrow },
    ];

    for (let i = 2; i < 30; i++) {
      const date = addDays(today, i);
      dates.push({ label: format(date, FORMAT), value: date });
    }

    return dates;
  }, [t]);

  return {
    daysFilter,
  };
};
