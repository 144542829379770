import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const SCROLL_RESTORE_PAGES = ['/schedule'];

const ScrollToTop: React.FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (!SCROLL_RESTORE_PAGES.some((page) => page === pathname)) {
      window.scrollTo({ behavior: 'instant' as ScrollBehavior, left: 0, top: 0 });
    }
  }, [pathname]);

  return null;
};

export default ScrollToTop;
