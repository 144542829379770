import { useRef } from 'react';

import { InfoErrorSmall } from '../../../../assets/icons/InforErrorSmall';
import { fillStringTemplate } from '../../../../helpers/fillStringTemplate';
import { useTranslations } from '../../../../hooks/useTranslations';
import { useGameRowIntersectionObserver } from '../../hooks/useGameRowIntersectionObserver';
import * as Ui from './EmptyMonthSlot.styles';

export type EmptyMonth = {
  month: number;
  monthName: string;
  startDate: Date;
  year: number;
};

interface EmptyMonthSlotProps {
  month: EmptyMonth;
  onChangeMonth: (month: number, year: number) => void;
  runIntersectionObserverOnElement: boolean;
}

export const EmptyMonthSlot = ({
  month,
  runIntersectionObserverOnElement,
  onChangeMonth,
}: EmptyMonthSlotProps) => {
  const t = useTranslations();
  const itemRef = useRef<HTMLDivElement>(null);

  useGameRowIntersectionObserver(itemRef, runIntersectionObserverOnElement, onChangeMonth);
  
  return (
    <Ui.Row ref={itemRef} key={month.month} data-monthyear={`${month.month}-${month.year}`}>
      <InfoErrorSmall />
      <Ui.Name>
        {fillStringTemplate(t.schedule_games_noGamesRow, {
          monthName: month.monthName,
        })}
      </Ui.Name>
    </Ui.Row>
  );
};
