import { useSelector } from 'react-redux';

import {
  LandingPage,
  MigrationConfiguration,
  PlayerConfiguration,
  RemoteConfigDataTypePromotionConfiguration,
  UpsellA,
} from '../../api/remoteConfigApi/remoteConfigApi.types';
import { PaymentOption } from '../../store/purchase';
import { RootState } from '../../store/types';
import { Envs } from './EnvProvider.types';

export const defaultEnvs = {
  ACTION_REQUIRED: false,
  ACTIVE_SUBSCRIPTIONS_URL: '',
  ADD_OR_REMOVE_FAVORITE_URL: '',
  ADD_SUBSCRIPTIONS_URL: '',
  ADD_TVOD_ORDER: '',
  ADOBE_AUTHORIZE_URL: '',
  ADOBE_GET_MEDIA_SHORT_TOKEN_URL: '',
  ADOBE_TOKENS_AUTHZ_URL: '',
  ANONYMIZE_ACCOUNT_URL: '',
  APPLE_STORE_APP_URL: '',
  AUTHORIZATION_ENDPOINT: '',
  CAST_CHANNEL_NAMESPACE: '',
  CAST_RECEIVER_APPLICATION_ID: '',
  CHANGE_EMAIL_URL: '',
  CHANGE_PASSWORD_URL: '',
  CLIENT_ID: '',
  CLIENT_NAME: '',
  CLIENT_REGISTERATION_ENDPOINT: '',
  CLIENT_SECRET: '',
  COMPANION_ADS_ENABLED: false,
  COMPANION_ADS_QUERY_PARAMETER: '',
  CONTAINERS_LIMIT: 100,
  CONTENT_AUTH_ENDPOINT: '',
  DATA_ZOOM_KEY: '',
  DATA_ZOOM_LOGGER: false,
  DEFAULT_CHANNEL: '',
  DEVICE_MODEL_NO: '',
  DEVICE_USER_AGENT: '',
  DISCONNECT_TVE_ACCOUNT_URL: '',
  EVERGENT_ADD_OUR_UPDATE_COUCH_RIGHTS: '',
  EVERGENT_API_KEY: '',
  EVERGENT_CHANNEL_PARTNER_ID: '',
  EVERGENT_CHECKOUT_URL: '',
  EVERGENT_COUCH_RIGHTS: '',
  EVERGENT_TVE_PROVIDER_URL: '',
  FIREBASE_API_KEY: String(process.env.REACT_APP_FIREBASE_API_KEY).trim() ?? '',
  FIREBASE_APP_ID: String(process.env.REACT_APP_FIREBASE_APP_ID).trim() ?? '',
  FIREBASE_AUTH_DOMAIN: String(process.env.REACT_APP_FIREBASE_AUTH_DOMAIN).trim() ?? '',
  FIREBASE_ENABLED: true,
  FIREBASE_MEASUREMENT_ID: String(process.env.REACT_APP_FIREBASE_MEASUREMENT_ID).trim() ?? '',
  FIREBASE_MESSAGING_SENDER_ID:
    String(process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID).trim() ?? '',
  FIREBASE_PROJECT_ID: String(process.env.REACT_APP_FIREBASE_PROJECT_ID).trim() ?? '',
  FIREBASE_STORAGE_BUCKET: String(process.env.REACT_APP_FIREBASE_STORAGE_BUCKET).trim() ?? '',
  FORCE_TIZEN: false,
  FORCE_ZONE_KEY: '',
  FORGOT_CONTACT_PASSWORD_URL: '',
  FREEWHEEL_API_URL: '',
  FREE_CONTENT_IDENTIFIER: '',
  GENERATE_ACTIVATION_CODE: '',
  GEO_LOCATION_LOOKUP_URL: '',
  GET_ADOBE_ACCESSE_TOKEN_URL: '',
  GET_COLLECTION_TAB_CONTAINER: '',
  GET_CONTACT_URL: '',
  GET_ENTITLEMENTS_URL: '',
  GET_FAVORITES_TEAMS_URL: '',
  GET_LIVE_EVENT: '',
  GET_LIVE_EVENTS: '',
  GET_LIVE_EVENTS_LIVE: '',
  GET_PAYMENTS: '',
  GET_PRODUCTS_URL: '',
  GET_PROMOTION_URL: '',
  GET_PRORATE_URL: '',
  GET_STOREFRONT_CHANNELS: '',
  GET_STOREFRONT_CHANNEL_AIRING: '',
  GET_STOREFRONT_CHANNEL_EPG: '',
  GET_STOREFRONT_LIST: '',
  GET_STOREFRONT_TABS: '',
  GET_STOREFRONT_TAB_CONTAINER: '',
  GET_STRING_URL: '',
  GET_SUBSCRIPTION_HISTORY_URL: '',
  GET_VIDEO_BY_ID_URL: '',
  GET_VOUCHER_CODE_URL: '',
  GET_ZIP_CODE_INFO_URL: '',
  GOOGLE_PLAY_STORE_APP_URL: '',
  HEARTBEAT_INTERVAL_SYNC: 60000,
  HEARTBEAT_MAX_ALLOWED_FAILURES: 2,
  HEARTBEAT_URL: '',
  HERO_BANNER_CAROUSEL_INTERVAL_IN_MS: 6000,
  IS_COMPANION_ADS_CLICKABILITY_ENABLED: true,
  IS_PLAYER_IN_STORYBOOK_MODE: false,
  IS_VISIBLE_PLAY_PAUSE_BUTTON_ON_LIVE: false,
  IS_VISIBLE_PROGRESSBAR_ON_LIVE: false,
  LANGUAGE: '',
  LOGIN_URL: '',
  LOG_OUT_ALL_DEVICES_URL: '',
  LOG_OUT_URL: '',
  MEDIA_CHECKREDEEM_URL: '',
  MPARTICLE_API_KEY_WEB: '',
  NUMBER_OF_HOURS_LOCATION_IS_VALID: 1,
  ODDS_ENABLED: false,
  ONETRUST_ENABLED: false,
  PARTNER_LOGO_VISIBILITY: true,
  PAUSE_SUBSCRIPTION_ENABLED: false,
  PAUSE_SUBSCRIPTION_URL: '',
  PAYPAL_CLIENT_ID: '',
  PLAYER_FORCE_BACK_TO_LIVE_BUTTON: false,
  PLAYER_FORCE_RESTART_BUTTON: false,
  PLAYER_LOGGER: false,
  POST_EXPRESS_CHECKOUT_URL: '',
  PROMOTIONS: [] as RemoteConfigDataTypePromotionConfiguration,
  QA_USER_NAME: '',
  QUICKPLAY_ASSETS_CDN_URL: '',
  REDUX_LOG: false,
  REFRESH_TOKEN_URL: '',
  REGISTER_DEVICE_URL: '',
  REMOVE_SUBSCRIPTION_URL: '',
  RESET_PASSWORD_URL: '',
  RESTRICT_TO_SUBSCRIPTION_ONLY: false,
  RESUME_SUBSCRIPTION_URL: '',
  SEARCH_ACCOUNT_V2_URL: '',
  SEASON_TICKET_HOLDER_PROMOTION_REDEMPTION: false,
  SET_STRING_URL: '',
  SHOULD_BLOCK_COUCH_RIGHTS_ERROR: false,
  SHOULD_BLOCK_LOCATION_ERROR: false,
  SHOW_UPDATE_PAYMENT_METHOD: false,
  SIGN_UP_URL: '',
  SPONSOR_LOGO_URL_L: '',
  SPONSOR_LOGO_URL_S: '',
  SQUARE_SDK_APP_ID: '',
  SQUARE_SDK_LOCATION_ID: '',
  STREAMCONCURRENCY_URL: '',
  STREAM_CONCURRENCY_MAX_RETRIES: 2,
  STREAM_CONCURRENCY_SYNC_INTERVAL_IN_MILLIS: 60000,
  TEAMS_LIST_URL_BY_ZONE: '',
  TEAMS_VIDEOS_URL: '',
  THUMB_IMAGE_URL: '',
  TRANSLATIONS_API_KEY: '',
  TRANSLATIONS_URL: '',
  TVE_ADOBE_REQUESTOR: '',
  TVE_ADOBE_RESOURCE: '',
  UPSELL: [] as Array<UpsellA>,
  VIP_USER_NAME: '',
  WEB_APP_URL: '',
  WEB_VIEW_CONFIG_API_KEY: '',
  WEB_VIEW_CONFIG_URL: '',
  X_CLIENT_ID: '',
  X_FORWARDED_FOR: '',
  availablePaymentOptions: [] as PaymentOption[],
  conviva: { ecoIntegrationIsEnabled: '', isEnabled: false, key: '', touchstoneURL: '' },
  heroConfig: {
    ctyIgnoringTransparency: {
      description: [] as Array<string>,
      title: [] as Array<string>,
    },
    promotionsDestination: [] as Envs['heroConfig']['promotionsDestination'],
  },
  landingPages: [] as LandingPage[],
  migration: {} as MigrationConfiguration,
  playerConfiguration: {} as PlayerConfiguration,
  webPathControl: { blockedPaths: [] as Array<string> },
};

export function useEnv() {
  const env = useSelector((state: RootState) => state.appConfig.env);
  return env;
}
