import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { useExtendedNavigate } from '../../../../hooks/useExtendedNavigate';
import { useSubscriptionNavigate } from '../../../../hooks/useSubscriptionNavigate';
import { useTranslations } from '../../../../hooks/useTranslations';
import { scheduleSelector } from '../../../../store/schedule';
import { Loader } from '../../../Loader/Loader';
import { LoadingErrorTryAgain } from '../../../LoadingErrorTryAgain/LoadingErrorTryAgain';
import { GAME_TABLE_ID } from '../../constants';
import { useGamesGuideData } from '../../hooks/useGamesGuideData';
import { useScrollToGame } from '../../hooks/useScrollToGame';
import { EmptyMonthSlot } from '../EmptyMonthSlot/EmptyMonthSlot';
import { GameRow } from '../GameRow/GameRow';
import { ContentWrapper, NoContentMessage } from '../Schedule.styles';

export const GamesGuide = ({
  onChangeMonth,
  selectedDate,
  runIntersectionObserverOnElement,
  setRunIntersectionObserverOnElement,
}: {
  onChangeMonth: (month: number, year: number) => void;
  selectedDate?: string | null;
  runIntersectionObserverOnElement: boolean;
  setRunIntersectionObserverOnElement: (value: boolean) => void;
}) => {
  const { games, loading, error } = useSelector(scheduleSelector);
  const gamesGuideData = useGamesGuideData();
  const t = useTranslations();
  const { checkSubscription } = useSubscriptionNavigate();
  useScrollToGame(setRunIntersectionObserverOnElement, selectedDate);
  const navigate = useExtendedNavigate();

  const handleTryAgain = useCallback(() => {
    navigate(0);
  }, [navigate]);

  if (!loading && error) {
    return (
      <ContentWrapper>
        <LoadingErrorTryAgain error={error} tryAgain={handleTryAgain} loading={loading} />
      </ContentWrapper>
    );
  }

  return (
    <div id={GAME_TABLE_ID} className='d-flex flex-grow-1'>
      {loading && (
        <ContentWrapper>
          <Loader />
        </ContentWrapper>
      )}
      {!loading && games.length === 0 && (
        <ContentWrapper>
          <NoContentMessage>{t.schedule_noContent}</NoContentMessage>
        </ContentWrapper>
      )}
      {!error && !loading && games.length > 0 && (
        <div className='w-100 mb-4'>
          {gamesGuideData.map((data) => {
            if ('title' in data) {
              const game = data;
              return (
                <GameRow
                  key={game.id}
                  game={game}
                  onChangeMonth={onChangeMonth}
                  checkSubscription={checkSubscription}
                  runIntersectionObserverOnElement={runIntersectionObserverOnElement}
                />
              );
            }
            return (
              <EmptyMonthSlot
                key={data.month}
                month={data}
                onChangeMonth={onChangeMonth}
                runIntersectionObserverOnElement={runIntersectionObserverOnElement}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};
