import { PropsWithChildren } from 'react';
import { useLocation } from 'react-router';

import { useEnv } from '../components/EnvProvider/EnvProvider';
import { Layout } from '../components/Layout/Layout';
import { useGetCurrentPathPattern } from '../hooks/useGetCurrentPathPattern';
import NotFound from '../pages/Error/NotFound';
import { checkDoesPathMatch } from '../utils/checkDoesPathMatch';
import { routesConfig } from './routesConfig';

export const RouteLayoutWrapper = ({ children }: PropsWithChildren) => {
  const location = useLocation();
  const currentPath = location.pathname;

  const pathPattern = useGetCurrentPathPattern();

  const routeSettings = routesConfig[pathPattern];
  const { webPathControl } = useEnv();

  if (webPathControl?.blockedPaths.some((path) => checkDoesPathMatch(path, currentPath))) {
    return <NotFound />;
  }

  if (!routeSettings || routeSettings.customLayout) {
    return <>{children}</>;
  }

  const { hasZipCodeValidation, isPrivate, noNavbar, noFooter, noContentPadding, forceNavbar } =
    routeSettings;

  return (
    <Layout
      noNavbar={noNavbar || (!forceNavbar && !isPrivate)}
      noFooter={noFooter}
      noZipBar={!hasZipCodeValidation}
      noContentPadding={noContentPadding}
    >
      {children}
    </Layout>
  );
};
