import { useDispatch } from 'react-redux';

import { TeamsConfig } from '../../api/remoteConfigApi/remoteConfigApi.types';
import { configActions } from '../../store/config';
import { AppDispatch } from '../../store/store';
import { Envs } from '../EnvProvider/EnvProvider.types';
import { Loader } from '../Loader/Loader';
import * as Ui from './EnvLoader.styles';

interface EnvLoaderProps {
  children: React.ReactNode;
  env: Envs;
  teams: TeamsConfig[];
  appReady: boolean;
}

export const EnvLoader: React.FC<EnvLoaderProps> = ({
  children,
  env,
  teams,
  appReady,
}: EnvLoaderProps) => {
  if (!appReady) {
    return (
      <Ui.Wrapper>
        <Loader />
      </Ui.Wrapper>
    );
  }

  const dispatch: AppDispatch = useDispatch();
  dispatch(configActions.setConfigAppSuccess({ env, teams }));

  return children as JSX.Element;
};
