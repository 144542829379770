import { useCallback } from 'react';
import { SetURLSearchParams, useLocation, useSearchParams } from 'react-router-dom';

export const useExtendedSearchParams = (): [URLSearchParams, SetURLSearchParams] => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const previousLocationPathname = location?.state?.previousLocation;

  const extendedSetSearchParams: SetURLSearchParams = useCallback(
    (init, navigationOptions) => {
      return setSearchParams(init, {
        ...navigationOptions,
        state: {
          previousLocation: previousLocationPathname,
        },
      });
    },
    [previousLocationPathname, setSearchParams],
  );

  return [searchParams, extendedSetSearchParams];
};
