import { createAsyncThunk } from '@reduxjs/toolkit';
import { FirebasePerformance, trace } from 'firebase/performance';

import evergentAuthApi from '../../api/authApi/EvergentAuthApi';
import {
  sessionStarted,
  signUpEvent,
} from '../../api/mParticlesApi/mParticleEvents/auth/authEvents';
import { mParticleApi } from '../../api/mParticlesApi/mParticlesApi';
import { CC_VALUE_KEY } from '../../components/playerUI/constants';
import { PerformanceMeasure } from '../../firebase/constants';
import { perf } from '../../firebase/firebase';
import { Log } from '../../helpers/CustomLogger';
import { cacheManager } from '../../utils';
import { getErrorMessage } from '../../utils/error';
import { DEBUG_VIEW_PASSWORD_KEY } from '../../utils/iseDebugViewEnabled';
import { getCouchRights } from '../couchRights';
import { storefrontActions } from '../storefront';
import { RootState } from '../types';
import { AUTH_SESSION_STORAGE_KEY } from './auth.constants';
import { actions, login, logout } from './auth.slice';
import { LoginPayload, SignUpPayload } from './auth.types';
import { authResponseToState } from './utils/authResponseToState';

export const loginUser = createAsyncThunk(login.type, async (payload: LoginPayload, thunksapi) => {
  const tracer = trace(perf as FirebasePerformance, PerformanceMeasure.SIGN_IN);
  tracer.start();
  try {
    const {
      appConfig: {
        env: {
          EVERGENT_API_KEY,
          EVERGENT_CHANNEL_PARTNER_ID,
          LOGIN_URL,
          QA_USER_NAME,
          VIP_USER_NAME,
        },
      },
    } = thunksapi.getState() as RootState;

    const loginResponse = await evergentAuthApi.login({
      ...payload,
      evergentApiKey: EVERGENT_API_KEY,
      evergentChannelPartnerId: EVERGENT_CHANNEL_PARTNER_ID,
      loginUrl: LOGIN_URL,
    });
    const options = {
      qaUserName: QA_USER_NAME,
      vipUserName: VIP_USER_NAME,
    };
    const session = authResponseToState(loginResponse, options);

    thunksapi.dispatch(actions.loginSuccess(session));
    thunksapi.dispatch(getCouchRights());
    cacheManager.save(AUTH_SESSION_STORAGE_KEY, session);
  } catch (error: unknown) {
    const errorMessage = getErrorMessage(error);

    const [code, message] = String(errorMessage).split('-');
    thunksapi.dispatch(actions.loginError({ code, message }));
  } finally {
    tracer.stop();
  }
});

export const signUp = createAsyncThunk(login.type, async (payload: SignUpPayload, thunksapi) => {
  const tracer = trace(perf as FirebasePerformance, PerformanceMeasure.SIGN_UP);
  tracer.start();
  try {
    const {
      appConfig: {
        env: {
          EVERGENT_API_KEY,
          EVERGENT_CHANNEL_PARTNER_ID,
          SIGN_UP_URL,
          QA_USER_NAME,
          VIP_USER_NAME,
        },
      },
    } = thunksapi.getState() as RootState;

    const signUpResponse = await evergentAuthApi.signUp({
      ...payload,
      evergentApiKey: EVERGENT_API_KEY,
      evergentChannelPartnerId: EVERGENT_CHANNEL_PARTNER_ID,
      signUpUrl: SIGN_UP_URL,
    });

    mParticleApi.createSession({
      customerid: signUpResponse.cpCustomerID,
      email: payload.username,
    });
    const options = {
      qaUserName: QA_USER_NAME,
      vipUserName: VIP_USER_NAME,
    };

    signUpEvent({
      email: payload.username,
      // eslint-disable-next-line camelcase
      user_id: signUpResponse.cpCustomerID,
    });

    const session = authResponseToState(signUpResponse, options);

    cacheManager.save(AUTH_SESSION_STORAGE_KEY, session);
    sessionStarted();
    thunksapi.dispatch(actions.signUpSuccess(session));
    thunksapi.dispatch(getCouchRights());
  } catch (error: unknown) {
    Log.error(error);
    const errorMessage = getErrorMessage(error);
    thunksapi.dispatch(actions.signUpError(errorMessage));
  } finally {
    tracer.stop();
  }
});

export const logOutAllDevices = createAsyncThunk(logout.type, async (payload: never, thunksapi) => {
  try {
    thunksapi.dispatch(actions.logoutLoading());

    const {
      appConfig: {
        env: { EVERGENT_API_KEY, EVERGENT_CHANNEL_PARTNER_ID, LOG_OUT_ALL_DEVICES_URL },
      },
    } = thunksapi.getState() as RootState;

    const logOutAllDevicesResponse = await evergentAuthApi.logOutAllDevices({
      evergentApiKey: EVERGENT_API_KEY,
      evergentChannelPartnerId: EVERGENT_CHANNEL_PARTNER_ID,
      logOutAllDevicesUrl: LOG_OUT_ALL_DEVICES_URL,
    });

    if (
      logOutAllDevicesResponse?.failureMessage &&
      logOutAllDevicesResponse?.failureMessage.length > 0
    ) {
      thunksapi.dispatch(
        actions.logoutError(logOutAllDevicesResponse?.failureMessage[0].errorMessage),
      );
    } else {
      thunksapi.dispatch(actions.logoutAllDevicesSuccess());
    }
  } catch (error: unknown) {
    Log.error(error);
    const errorMessage = getErrorMessage(error);

    thunksapi.dispatch(actions.logoutError(errorMessage));
  }
  cacheManager.remove(AUTH_SESSION_STORAGE_KEY);
});

export const logoutUser = createAsyncThunk(logout.type, async (payload: never, thunksapi) => {
  try {
    const {
      appConfig: {
        env: { EVERGENT_API_KEY, EVERGENT_CHANNEL_PARTNER_ID, LOG_OUT_URL },
      },
    } = thunksapi.getState() as RootState;

    thunksapi.dispatch(actions.logoutLoading());
    const logOutResponse = await evergentAuthApi.logout({
      evergentApiKey: EVERGENT_API_KEY,
      evergentChannelPartnerId: EVERGENT_CHANNEL_PARTNER_ID,
      logOutUrl: LOG_OUT_URL,
    });

    if (logOutResponse?.failureMessage && logOutResponse?.failureMessage.length > 0) {
      thunksapi.dispatch(actions.logoutError(logOutResponse?.failureMessage[0].errorMessage));
    } else {
      thunksapi.dispatch(actions.logoutSuccess());
      thunksapi.dispatch(storefrontActions.clearStorefront());
    }
  } catch (error: unknown) {
    Log.error(error);
    const errorMessage = getErrorMessage(error);
    thunksapi.dispatch(actions.logoutError(errorMessage));
  }
  localStorage.removeItem(DEBUG_VIEW_PASSWORD_KEY);
  localStorage.removeItem(CC_VALUE_KEY);

  cacheManager.remove(AUTH_SESSION_STORAGE_KEY);
});

export const checkAuthCompleted = createAsyncThunk(
  login.type,
  async (payload: never, thunksapi) => {
    const state = thunksapi.getState() as RootState;
    const { entitlements, activeSubscriptions, userData } = state;
    if (entitlements.loaded && activeSubscriptions.loaded && userData.loaded) {
      thunksapi.dispatch(actions.authCompleted());
    }
  },
);

export const clearSession = createAsyncThunk(
  login.type,

  async (payload: never, thunksapi) => {
    thunksapi.dispatch(actions.logoutSuccess());

    cacheManager.remove(AUTH_SESSION_STORAGE_KEY);
  },
);
