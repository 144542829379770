import { createAsyncThunk } from '@reduxjs/toolkit';

import evergentAuthApi from '../../api/authApi/EvergentAuthApi';
import { GetProductsResponseItem } from '../../api/authApi/authApi.types';
import { Log } from '../../helpers/CustomLogger';
import { getPurchaseItem } from '../purchase';
import { RootState } from '../types';

export const RECOMMENT_PRODUCT_IDX = 1;

export const fetchProducts = createAsyncThunk('products/fetchProducts', async (_, thunkAPI) => {
  try {
    const {
      appConfig: {
        env: { EVERGENT_API_KEY, EVERGENT_CHANNEL_PARTNER_ID, GET_PRODUCTS_URL },
      },
    } = thunkAPI.getState() as RootState;

    const response = await evergentAuthApi.getProducts({
      evergentApiKey: EVERGENT_API_KEY,
      evergentChannelPartnerId: EVERGENT_CHANNEL_PARTNER_ID,
      getProductsUrl: GET_PRODUCTS_URL,
      returnTVOD: true,
    });

    const sortedProducts = response.sort((p1, p2) => p1.displayOrder - p2.displayOrder);
    const selectedProduct = sortedProducts[RECOMMENT_PRODUCT_IDX] ?? sortedProducts[0];

    thunkAPI.dispatch(getPurchaseItem(selectedProduct?.skuORQuickCode));

    return {
      products: sortedProducts,
      selectedProduct,
    };
  } catch (error: unknown) {
    Log.error('fetchWebViewConfig', error);
    return thunkAPI.rejectWithValue(
      'We have not been able to load the plans. Please try again later',
    );
  }
});

export const selectProduct = createAsyncThunk(
  'products/selectProduct',
  (product: GetProductsResponseItem, thunkAPI) => {
    thunkAPI.dispatch(getPurchaseItem(product?.skuORQuickCode));

    return product;
  },
);
