import mParticle, { IdentityApiData, User } from '@mparticle/web-sdk';

import { Log } from '../../helpers/CustomLogger';
import { AUTH_SESSION_STORAGE_KEY, SessionType } from '../../store/auth';
import { cacheManager } from '../../utils';
import { MPARTICLE_SESSION_KEY } from './constants';
import { getMParticleConfig, getPlatformByFlowId } from './mParticle.utils';
import { mParticleConfigOptions } from './mParticleApi.types';
import { loginEvent, sessionStarted } from './mParticleEvents/auth/authEvents';

const mParticleApi = {
  ...(function () {
    let _readyPromiseResolve: (value: unknown) => void = () => undefined;
    const _readyPromise = new Promise(function (resolve) {
      _readyPromiseResolve = resolve;
    });
    return { _readyPromise, _readyPromiseResolve };
  })(),

  // Sign Up mParticles
  createSession: function (userIdentities: { customerid: string; email: string }) {
    cacheManager.save(MPARTICLE_SESSION_KEY, userIdentities);

    mParticle.Identity.identify(
      {
        userIdentities: {
          ...userIdentities,
        },
      },

      (result: { getUser: () => User }) => {
        if (result.getUser()) {
          const user = result.getUser();
          user.getUserIdentities();
        } else {
          // the IDSync call failed
        }
      },
    );
  },

  init: function (mparticleApiKey: string, options: mParticleConfigOptions) {
    if (mparticleApiKey) {
      const mParticleConfig = getMParticleConfig(options);
      mParticle.init(mparticleApiKey, mParticleConfig);
    } else {
      // setApiKeyModalOpen(true);
      Log.error('Please add your mParticle API Key');
    }
  },
  pageViewEvent: function (
    eventName: string,
    params: mParticle.SDKEventAttrs,
    flags?: mParticle.SDKEventCustomFlags,
  ) {
    return this._readyPromise.then(() => {
      const platform = getPlatformByFlowId();
      return mParticle.logPageView(
        eventName,
        { ...this.platformParams, platform, ...params },
        flags,
      );
    });
  },
  platformParams: {} as mParticle.SDKEventAttrs,
  removeAttributeFromUserData: function (attribute: { key: string }) {
    const user = mParticle.Identity.getCurrentUser();
    user.removeUserAttribute(attribute.key);
  },

  setPlatformParams: function (platformParams: mParticle.SDKEventAttrs) {
    this.platformParams = platformParams;
    this._readyPromiseResolve(true);
  },

  setUserData: function (attribute: { key: string; value: string }) {
    const user = mParticle.Identity.getCurrentUser();
    user.setUserAttribute(attribute.key, attribute.value);
  },

  setUserDataList: function (attribute: { key: string; value: string[] }) {
    const user = mParticle.Identity.getCurrentUser();
    user.setUserAttributeList(attribute.key, attribute.value);
  },
  // Login mParticles
  startSession: function (userIdentities: { customerid: string; email: string }) {
    cacheManager.save(MPARTICLE_SESSION_KEY, userIdentities);
    const session: SessionType | null = cacheManager.load(AUTH_SESSION_STORAGE_KEY);
    mParticle.Identity.login(
      {
        userIdentities: {
          ...userIdentities,
          other: session?.accountRole ? session?.accountRole : 'Default User',
        },
      },

      (result: { getUser: () => User }) => {
        if (result.getUser()) {
          const user: User = result.getUser();
          const identities: IdentityApiData = user.getUserIdentities();
          identities.userIdentities.email && loginEvent({ email: identities.userIdentities.email });
          sessionStarted();
        } else {
          // the IDSync call failed
        }
      },
    );
  },

  triggerEvent: function (
    eventName: string,
    eventType: mParticle.EventType,
    params: mParticle.SDKEventAttrs,
  ) {
    return this._readyPromise.then(() => {
      const platform = getPlatformByFlowId();
      return mParticle.logEvent(eventName, eventType, {
        ...this.platformParams,
        platform,
        ...params,
      });
    });
  },
  // Update user data mParticles ( this must not change the cpId )
  updateSession: function (userIdentities: { email: string }) {
    cacheManager.save(MPARTICLE_SESSION_KEY, userIdentities);
    const session: SessionType | null = cacheManager.load(AUTH_SESSION_STORAGE_KEY);
    mParticle.Identity.modify(
      {
        userIdentities: {
          ...userIdentities,
          other: session?.accountRole ? session?.accountRole : 'Default User',
        },
      },

      (result: { getUser: () => User }) => {
        if (result.getUser()) {
          const user = result.getUser();
          user.getUserIdentities();
        } else {
          // the IDSync call failed
        }
      },
    );
  },
};

export { mParticleApi };
