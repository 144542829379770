import { trackPageView } from '@convivainc/conviva-js-appanalytics';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { logPageView } from '../api/mParticlesApi/mParticleEvents/generic/genericEvents';
import { routesConfig } from '../routes/routesConfig';
import useGetUtmParams from './mParticle/useGetUtmParams';

const getRouteName = (path: string) => routesConfig[path]?.screenName;

export const useLogScreenViewedEvent = (screenName?: string, preventEvent?: boolean) => {
  const location = useLocation();
  const utmParams = useGetUtmParams();

  const isFirstRender = useRef(1);
  const previousScreenName = useRef<string | undefined>(undefined);

  useEffect(() => {
    if (!preventEvent) {
      // Log event when:
      // 1. First app open
      // 2. screen name has changed
      // 3. location has changed
      if (
        isFirstRender.current ||
        previousScreenName.current !== screenName ||
        (!isFirstRender.current && location?.state?.previousLocation)
      ) {
        const screen = screenName ?? getRouteName(location.pathname);

        if (screen) {
          previousScreenName.current = screenName;

          logPageView({
            attributes: {
              ...utmParams,
              path: window.location.pathname,
              referrer: location?.state?.previousLocation || document.referrer,
              screen,
              search: window.location.search,
              url: window.location.href,
            },
          });

          trackPageView({ title: screenName });
        }
      }
      isFirstRender.current = 0;
    }
  }, [location?.state, screenName]);
};
