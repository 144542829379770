import styled from 'styled-components';

import { Button as Btn } from '../../components/Button';
import { bolder } from '../../components/Common';
import { breakpoint } from '../../utils/breakpoint';
import { bgBlue, flexCenter } from '../../utils/styled/mixins';

export const Container = styled.div`
  font-family: 'GothamNarrow';
  display: flex;
  flex-direction: column;
  ${bgBlue}
  min-height: 100vh;
  min-height: 100svh;
`;

export const Wrapper = styled.div`
  ${flexCenter}
  z-index: ${({ theme }) => theme.zIndex.playerButtons};
  min-height: 100vh;
`;

export const WrapperInner = styled.div`
  ${flexCenter}
  flex-direction: column;
  gap: 1.5rem;
  padding: 0 1rem;
  ${breakpoint('lg')`
  	gap: 2.5rem;
  `}
`;

export const LogoWrapper = styled.div`
  width: 200px;
  height: 200px;

  > svg {
    width: 100%;
    height: 100%;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  ${breakpoint('lg')`
    gap: 1.5rem;
  `}
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.colors['pure-white']};
  font-family: 'GothamNarrow';
  font-size: 1.25rem;
  font-style: normal;
  ${bolder}
  line-height: 1.2;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  text-align: center;

  ${breakpoint('xs')`
     font-size: 2.5rem;
  `}
`;

export const Button = styled(Btn)`
  width: 100%;
  ${breakpoint('lg')`
 	  width: 16.5rem;
  `}
`;
