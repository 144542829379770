import { yupResolver } from '@hookform/resolvers/yup';
import { AnyAction } from '@reduxjs/toolkit';
import { flatMap } from 'lodash-es';
import { PropsWithChildren, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AnyObjectSchema } from 'yup';

import { ROUTES, RouteName } from '../../Constants/routes';
import { useExtendedNavigate } from '../../hooks/useExtendedNavigate';
import { useForm } from '../../hooks/useForm';
import { useTranslations } from '../../hooks/useTranslations';
import { AppDispatch } from '../../store/store';
import Link from '../Link';
import { PasswordInput } from '../PasswordInput/PasswordInput';
import { Input } from '../atoms';
import { createInitialValues, sortFormFields } from './AuthForm.helpers';
import * as AuthFormUi from './AuthForm.styles';
import { AuthFormFields, TAuthFormFieldsAttributes } from './AuthForm.types';

type LoginWebProps = PropsWithChildren & {
  onSuccess: (p: AuthFormFields) => void;
  validationSchema: AnyObjectSchema;
  displayBulletsForErrors?: boolean;
  error?: string | null;
  cleanupErrorAction: () => AnyAction;
  formFields: TAuthFormFieldsAttributes;
  submitButtonText: string;
  secondaryButton?: {
    text: string;
    route: RouteName;
    onClick?: () => void;
  };
  formTitle?: string;
};

export const AuthForm = ({
  children,
  validationSchema,
  displayBulletsForErrors = false,
  onSuccess,
  error,
  cleanupErrorAction,
  formFields,
  submitButtonText,
  secondaryButton,
  formTitle,
}: LoginWebProps) => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useExtendedNavigate();
  const t = useTranslations();
  const sortedFormFields = Object.entries(formFields).sort(sortFormFields);

  const { register, handleSubmit, formState } = useForm({
    criteriaMode: 'all',
    defaultValues: createInitialValues(formFields),
    mode: 'onTouched',
    resolver: yupResolver(validationSchema, {
      abortEarly: false,
    }),
  });

  const handleFormSubmit = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    handleSubmit(onSuccess)();
  };

  // Clean errors on unmount component
  useEffect(() => {
    return () => {
      dispatch(cleanupErrorAction());
    };
  }, []);

  const formattedErrorArray = (
    error: string | string[] | undefined,
    detailedError = false,
    formField?: string,
  ) => {
    if (!error) {
      return null;
    }

    const errorMessages =
      typeof error === 'string' ? (
        <AuthFormUi.ErrorLine key={error}>{error}</AuthFormUi.ErrorLine>
      ) : (
        <AuthFormUi.ErrorList
          $displayBullets={detailedError && displayBulletsForErrors}
          key={formField}
        >
          {error.map((err) => (
            <AuthFormUi.ErrorListItem key={err}>{err}</AuthFormUi.ErrorListItem>
          ))}
        </AuthFormUi.ErrorList>
      );

    if (detailedError) {
      return (
        <AuthFormUi.PasswordErrorWrapper key={formField}>
          <AuthFormUi.ErrorLineBold>{t.signUp_password_errorTitle}</AuthFormUi.ErrorLineBold>
          <AuthFormUi.ErrorLine>{t.signUp_password_errorSubtitle}</AuthFormUi.ErrorLine>
          {errorMessages}
        </AuthFormUi.PasswordErrorWrapper>
      );
    }

    return errorMessages;
  };

  const getErrors = () => {
    const errorEntries = Object.entries(formState.errors);

    return error || errorEntries.length ? (
      <AuthFormUi.ErrorContentWrapper>
        {error && <AuthFormUi.ErrorLine>{error}</AuthFormUi.ErrorLine>}
        {errorEntries
          .sort(([a], [b]) => formFields[a].errorOrder - formFields[b].errorOrder)
          .map(([formField, error]) => {
            return formattedErrorArray(
              Object.values(flatMap(error?.types) || {}),
              formFields[formField]?.detailedError,
              formField,
            );
          })}
      </AuthFormUi.ErrorContentWrapper>
    ) : null;
  };

  const submitOnEnter: React.KeyboardEventHandler<HTMLFormElement> = (e) => {
    if (e?.key === 'Enter') {
      handleFormSubmit();
    }
  };

  return (
    <AuthFormUi.Form onKeyDown={submitOnEnter}>
      {formTitle && <AuthFormUi.FormTitle>{formTitle}</AuthFormUi.FormTitle>}

      <div>
        <AuthFormUi.FormInputCol>
          {sortedFormFields.map(([formField, { placeholder, type, disabled }]) => {
            const InputComponent = type === 'password' ? PasswordInput : Input;
            return (
              <InputComponent
                key={formField}
                {...register(formField)}
                type={type}
                placeholder={placeholder}
                mb={1.5}
                disabled={disabled}
              />
            );
          })}
          <AuthFormUi.ErrorWrapper>{getErrors()}</AuthFormUi.ErrorWrapper>
        </AuthFormUi.FormInputCol>
      </div>

      <AuthFormUi.ButtonContainer>
        <AuthFormUi.ButtonWrapperCol>
          {children}
          <AuthFormUi.Button onPress={handleFormSubmit}>{submitButtonText}</AuthFormUi.Button>
          {secondaryButton && (
            <Link
              type='button'
              onPress={() => {
                navigate(ROUTES[secondaryButton.route]);
                secondaryButton?.onClick && secondaryButton.onClick();
              }}
            >
              {secondaryButton.text}
            </Link>
          )}
        </AuthFormUi.ButtonWrapperCol>
      </AuthFormUi.ButtonContainer>
    </AuthFormUi.Form>
  );
};

AuthForm.displayName = 'AuthForm';
