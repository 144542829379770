import { PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';

import { ROUTES, RouteName } from '../../Constants/routes';
import { useExtendedNavigate } from '../../hooks/useExtendedNavigate';
import { ClickEventDispatcher } from '../ClickEventDispatcher/ClickEventDispatcher';
import { Col, Row } from '../Common';
import * as Ui from './AuthPageWrapper.styles';

export type TAuthPageWrapperProps = PropsWithChildren & {
  screen: string;
  location: string;
  pathname: string;
  destination?: string;
  title?: string;
  subTitle?: string;
  previousLocation?: string;
  onBackButtonClick?: () => void;
};

export const AuthPageWrapper = ({
  screen,
  pathname,
  destination = '/',
  children,
  title,
  subTitle,
  onBackButtonClick,
  previousLocation,
}: TAuthPageWrapperProps) => {
  const location = useLocation();
  const navigate = useExtendedNavigate();

  const goBack = () => {
    onBackButtonClick?.();
    navigate(previousLocation ?? (location?.state?.previousLocation || ROUTES[RouteName.Welcome]));
  };

  return (
    <Ui.ContentContainer>
      {location.pathname === pathname && (
        <ClickEventDispatcher
          destination={destination}
          location={`Module: ${location}`}
          screen={screen}
          text='CTA: Cancel'
        >
          <Ui.StyledBackButton onClick={goBack} />
        </ClickEventDispatcher>
      )}

      <Ui.Logo />

      {title ? <Ui.Title>{title}</Ui.Title> : null}
      {subTitle ? (
        <Row>
          <Col xs={12} xl={{ offset: 3, span: 6 }}>
            <Ui.Subtitle>{subTitle}</Ui.Subtitle>
          </Col>
        </Row>
      ) : null}
      {children}
    </Ui.ContentContainer>
  );
};

AuthPageWrapper.displayName = 'AuthPageWrapper;';
