import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ALL } from '../../../../Constants/TeamSelector';
import { TeamData } from '../../../../api';
import { useDateSearchParam } from '../../../../hooks/params/useDateSearchParam';
import { useTeamSearchParam } from '../../../../hooks/params/useTeamSearchParam';
import { ScheduleTabs } from '../../../../pages/Schedule/Schedule';
import { getGames } from '../../../../store/schedule';
import { AppDispatch } from '../../../../store/store';
import { getDate } from '../../../../utils/date';
import { scrollToPosition, throttleScroll } from '../../../../utils/scroll';
import { GAME_TABLE_ID, SCROLLING_TIME_IN_MS } from '../../constants';
import { getScrollingPositionOfElement } from '../../utils/getScrollingPosition';
import { GamesGuide } from '../GamesGuide/GamesGuide';
import { MonthsList } from '../MonthsList/MonthsList';
import * as Ui from '../Schedule.styles';
import { TabSelector } from '../TabSelector/TabSelector';
import { TeamsFilter } from '../TeamsFilter';

export const GameBody = ({
  selectedTab,
  onChangeTab,
}: {
  selectedTab: ScheduleTabs;
  onChangeTab: (tab: ScheduleTabs) => void;
}) => {
  const dispatch: AppDispatch = useDispatch();

  const { selectTeam, selectedTeam, selectedTeamId } = useTeamSearchParam({});
  const { selectDate, selectedDate } = useDateSearchParam({});
  const selectedMonth = getDate(selectedDate).getMonth();
  const [runIntersectionObserverOnElement, setRunIntersectionObserverOnElement] = useState(false);

  const handleTeamChange = useCallback(
    (team: TeamData) => {
      setRunIntersectionObserverOnElement(false);

      selectTeam(team);
    },
    [selectTeam],
  );

  const handleMonthChange = useCallback(
    (month: number, year: number) => {
      const date = new Date(year, month, 1);
      selectDate(date);
    },
    [selectDate],
  );

  const handleMonthClick = useCallback(
    (month: number, year: number) => {
      setRunIntersectionObserverOnElement(false);

      const date = new Date(year, month, 1);
      selectDate(date);

      const firstGameInGivenMonth = document.querySelector(`[data-monthyear="${month}-${year}"]`);

      if (firstGameInGivenMonth) {
        scrollToPosition(
          getScrollingPositionOfElement(firstGameInGivenMonth, GAME_TABLE_ID),
          SCROLLING_TIME_IN_MS,
          () => {
            setRunIntersectionObserverOnElement(true);
          },
        );
      }
    },
    [selectDate],
  );

  const handleMonthClickDebounced = useCallback(
    throttleScroll(handleMonthClick, SCROLLING_TIME_IN_MS),
    [handleMonthClick],
  );

  useEffect(() => {
    const teamShortName = (selectedTeam?.id === ALL ? ALL : selectedTeam?.teamShortName) || ALL;

    dispatch(getGames({ team: teamShortName }));
  }, [selectedTeam?.teamId]);

  return (
    <Ui.SchedulePage>
      <Ui.Navigation>
        <Ui.NavigationContainer>
          <TabSelector selectedTab={selectedTab} onChangeTab={onChangeTab} />
          <TeamsFilter selectedTeamId={selectedTeamId} onTeamChange={handleTeamChange} />
          <MonthsList selectedMonth={selectedMonth} onChangeMonth={handleMonthClickDebounced} />
        </Ui.NavigationContainer>
      </Ui.Navigation>

      <Ui.ContentContainer>
        <GamesGuide
          setRunIntersectionObserverOnElement={setRunIntersectionObserverOnElement}
          onChangeMonth={handleMonthChange}
          selectedDate={selectedDate}
          runIntersectionObserverOnElement={runIntersectionObserverOnElement}
        />
      </Ui.ContentContainer>
    </Ui.SchedulePage>
  );
};
