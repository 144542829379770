import { useSelector } from 'react-redux';

import { ROUTES, RouteName } from '../../Constants/routes';
import { MsgLogo } from '../../assets/icons';
import { useExtendedNavigate } from '../../hooks/useExtendedNavigate';
import { useTranslations } from '../../hooks/useTranslations';
import { isAuthedSelector } from '../../store/auth';
import * as Ui from './NotFound.styles';

const NotFound = () => {
  const navigate = useExtendedNavigate();
  const t = useTranslations();
  const isAuthed = useSelector(isAuthedSelector);
  const backRoute = isAuthed ? ROUTES[RouteName.Home] : ROUTES[RouteName.Welcome];

  return (
    <Ui.Container>
      <Ui.Wrapper>
        <Ui.WrapperInner>
          <Ui.LogoWrapper>
            <MsgLogo />
          </Ui.LogoWrapper>

          <Ui.TextWrapper>
            <Ui.Title>{t.errorNotFound_pageNotFound}</Ui.Title>
          </Ui.TextWrapper>

          <Ui.Button onPress={() => navigate(backRoute)}>{t.errorNotFound_back}</Ui.Button>
        </Ui.WrapperInner>
      </Ui.Wrapper>
    </Ui.Container>
  );
};

export default NotFound;
