import styled from 'styled-components';

import { breakpoint } from '../../utils/breakpoint';
import { bgBlue } from '../../utils/styled/mixins';
import { FOOTER_HEIGHT_MOBILE } from '../Footer/Footer.styles';
import { NAVIGATION_HEIGHT } from '../Navigation/Navigation.styles';
import {
  ZIP_CODE_FORM_HEIGHT_DESKTOP,
  ZIP_CODE_FORM_HEIGHT_MOBILE,
} from '../ZipCodeForm/ZipCodeForm.styles';

export const ZIPCODE_BAR_TRANSITION_TIME_MS = 500;

export const Root = styled.div<{
  $isZipBarVisible: boolean;
  $noFooter?: boolean;
}>`
  font-family: 'GothamNarrow';
  transition: ${ZIPCODE_BAR_TRANSITION_TIME_MS}ms;
  display: flex;
  flex-direction: column;
  position: relative;
  ${bgBlue}

  ${({ $noFooter }) =>
    $noFooter
      ? `min-height: 100vh;
         min-height: 100svh;`
      : `min-height: calc(100vh + ${FOOTER_HEIGHT_MOBILE}); 
         min-height: calc(100svh + ${FOOTER_HEIGHT_MOBILE});`}


  ${breakpoint('xl')`
    min-height: 100vh;
    min-height: 100svh;
  `}

  ${({ $isZipBarVisible }) =>
    $isZipBarVisible && breakpoint('lg')`padding-top: ${ZIP_CODE_FORM_HEIGHT_DESKTOP}px;`}

  ${({ $isZipBarVisible }) => $isZipBarVisible && `padding-top: ${ZIP_CODE_FORM_HEIGHT_MOBILE}px;`}
`;

export const Main = styled.main<{ $noNavPadding?: boolean; $noContentPadding?: boolean }>`
  position: relative;
  z-index: 1;
  flex: 1;
  display: flex;
  flex-direction: column;

  ${({ $noContentPadding }) =>
    !$noContentPadding &&
    `
    padding-bottom: 1.5rem;
    
    ${breakpoint('xl')`
      padding-bottom: 2rem;  
    `}
  `};

  ${({ $noNavPadding }) => !$noNavPadding && `padding-top: ${NAVIGATION_HEIGHT}px;`};
`;
